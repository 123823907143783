import axios from "axios";
import { Notify } from "vant";
console.log(process.env);
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000,
});

service.interceptors.request.use(
  (config) => {
    config.headers["key"] = "8fantasy2023";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code !== 0) {
      Notify({ type: "danger", message: res.message || "Error" });
      return Promise.reject(new Error(res.message || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    Notify({ type: "danger", message: error.message || "Error" });
    return Promise.reject(error);
  }
);
export default service;
