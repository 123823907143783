import Vue from "vue";
import Vuex from "vuex";
import { Toast } from "vant";

Vue.use(Vuex);
const userAgent = navigator.userAgent;
const IOS_APP_URL = "efantasy://";
const IOS_DOWNLOAD_URL =
  "https://apps.apple.com/cn/app/%E5%85%AB%E5%BA%A6%E5%B9%BB%E6%83%B3/id1067200707";
// const IOS_DOWNLOAD_URL = "https://itunes.apple/cn/app/id1067200707";
const ANDROID_APP_URL = "efantasy://";
const ANDROID_DOWNLOAD_URL = "http://download.8fantasy.com/get";
let timer = null;

export default new Vuex.Store({
  state: {
    isiOS: !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
  },
  getters: {},
  mutations: {},
  actions: {
    download({ state }) {
      if (
        userAgent.toLowerCase().match(/MicroMessenger/i) == "micromessenger"
      ) {
        Toast("点击右上角，在默认浏览器打开");
        return;
      }

      // window.open(state.isiOS ? IOS_APP_URL : ANDROID_APP_URL, "_blank");
      window.location.href = state.isiOS ? IOS_APP_URL : ANDROID_APP_URL;

      // 3s之后跳转到APP下载页
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        const hidden =
          window.document.hidden ||
          window.document.mozHidden ||
          window.document.msHidden ||
          window.document.webkitHidden;
        if (typeof hidden === "undefined" || !hidden) {
          window.location.href = state.isiOS
            ? IOS_DOWNLOAD_URL
            : ANDROID_DOWNLOAD_URL;
        }
      }, 3000);

      // 跳转app之后禁止再跳转中间页
      document.addEventListener("visibilitychange", () => {
        clearTimeout(timer);
      });
    },
  },
  modules: {},
});
