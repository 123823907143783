<template>
  <div class="home">
    <div class="homeFooter clearfix" @click="goUrl">
      <img src="@/assets/download.png" class="homeFooterImg" align="center" />
      <!--      <div class="fl homeFooterDiv">八度幻想App</div>-->
      <!--      <div class="homeFooterBtn fr" @click="goUrl">-->
      <!--        打开看看-->
      <!--        &lt;!&ndash; <img src="@/assets/arrow.png" />&ndash;&gt;-->
      <!--        &lt;!&ndash; 下载&ndash;&gt;-->
      <!--      </div>-->
    </div>

    <van-swipe
      v-if="detailData.photo_list && detailData.photo_list.length > 0"
      @change="onChange"
      :autoplay="4000"
      :show-indicators="false"
      class="swipeDiv"
    >
      <van-swipe-item v-for="item in detailData.photo_list" :key="item.id">
        <img :src="item.url" alt="" />
      </van-swipe-item>
    </van-swipe>
    <div class="swipeIndicator">
      <span
        v-for="(item, index) in detailData.photo_list"
        :key="index"
        :class="current === index ? 'active' : ''"
      ></span>
    </div>
    <div v-if="detailData.voice_list[0]" class="audiodiv clearfix">
      <van-icon
        v-if="!isPlay"
        ref="play"
        @click="play"
        class="audiodivBtn fl"
        name="play-circle"
      />
      <van-icon
        v-else
        ref="pause"
        name="pause-circle"
        @click="pause"
        class="audiodivBtn fl"
      />
      <div
        :class="
          isPlay ? 'audiodivImage audiodivImageActive fl' : 'audiodivImage fl'
        "
      >
        <div class="yinjie four"></div>
        <div class="yinjie five"></div>
        <div class="yinjie six"></div>
        <div class="yinjie seven"></div>
        <div class="yinjie six"></div>
        <div class="yinjie five"></div>
        <div class="yinjie four"></div>
      </div>
      <div class="audiodivTime fr">{{ parseInt(timeNum) }}s</div>
      <audio
        ref="audio"
        id="audio"
        :src="detailData.voice_list[0].url"
        preload
      />
    </div>
    <div class="pageCont">
      <div class="pageTime">{{ detailData.create_time }}</div>
      <div class="pageTxt word_q" v-html="content"></div>
    </div>
  </div>
</template>

<script>
import { getDetail } from "@/api/index";
import maskIcon from "@/assets/mask.png";
export default {
  data() {
    return {
      audioDuration: 0,
      timeNum: 0,
      isPlay: false,
      interval: null,
      current: 0,
      detailData: {
        photo_list: [],
        content: "",
        create_time: "",
        user_name: "",
        voice_list: [],
      },
    };
  },
  computed: {
    content() {
      return this.detailData.content.replace(
        /(<mask>.*?<\/mask>)|(\[mask\].*?\[\/mask\])/g,
        `<span style="color: #6B5ACD">【</span><img src=${maskIcon} class="maskIcon"/><span style="color: #6B5ACD">】</span>`
      );
    },
  },
  mounted() {
    getDetail({
      apiVersion: "V3.1",
      cmdid: 21005,
      uid: "",
      oamid: "",
      eid: "",
      timestamp: "",
      token: "",
      params: {
        dreamId: this.$route.query.id || 105707,
      },
    }).then((res) => {
      const { info } = res;
      this.detailData = info;
      this.$nextTick(() => {
        if (info.voice_list[0]) {
          const audio = document.getElementById("audio");
          if (
            typeof WeixinJSBridge == "object" &&
            // eslint-disable-next-line no-undef
            typeof WeixinJSBridge.invoke == "function"
          ) {
            // eslint-disable-next-line no-undef
            WeixinJSBridge.invoke("getNetworkType", {}, function () {
              audio.load();
            });
          }
          audio.addEventListener("canplay", () => {
            this.audioDuration = audio.duration;
            this.timeNum = audio.duration;
          });
        }
      });
    });
  },
  created() {},
  methods: {
    goUrl() {
      this.$store.dispatch("download");
    },
    play() {
      // 播放
      this.$refs.audio.play();
      // 暂停动画
      this.isPlay = true;
      //开启时间
      this.timeNumFun();
    },
    pause() {
      // 暂停
      this.$refs.audio.pause();
      // 暂停动画
      this.isPlay = false;
      //暂停时间
      clearInterval(this.interval);
    },
    timeNumFun() {
      this.interval = setInterval(() => {
        this.timeNum--;
        if (this.timeNum < 0) {
          this.pause();
          this.timeNum = this.audioDuration;
        }
      }, 1000);
    },
    onChange(index) {
      this.current = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  font-family: PingFangSC-Regular, PingFang SC;
  padding-top: 72px;
  .swipeDiv {
    //width: 100%;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .swipeIndicator {
    text-align: center;
    height: 32px;
    span {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #999;
      display: inline-block;
      vertical-align: top;
      margin: 14px 4px;
    }
    .active {
      background: #6b5acd;
    }
  }
  .audiodiv {
    width: 168px;
    height: 42px;
    background: url(@/assets/vioBg.png) no-repeat center;
    background-size: 100% 100%;
    margin: 0 0 16px 14px;
    overflow: hidden;
    .audiodivBtn {
      color: #6a5acd;
      font-size: 24px;
      margin: 8px 0 0 12px;
    }
    .audiodivImage {
      text-align: center;
      width: 70px;
      height: 40px;
      margin: 0 0 0 10px;
      .yinjie {
        display: inline-block;
        vertical-align: top;
        width: 4px;
        height: 20px;
        background-color: #d8d8d8;
        margin: 10px 0 0 4px;
        border-radius: 54px;
      }
      .four {
        transform: scale(1, 0.2);
      }
      .five {
        transform: scale(1, 0.4);
      }
      .six {
        transform: scale(1, 0.6);
      }
      .seven {
        transform: scale(1, 0.8);
      }
    }
    .audiodivImageActive {
      @keyframes tiao {
        0% {
          transform: scale(1, 1);
        }
        100% {
          transform: scale(1, 0.2);
        }
      }
      .four {
        animation: tiao 0.5s 1.4s linear infinite alternate;
      }
      .five {
        animation: tiao 0.5s 1.2s linear infinite alternate;
      }
      .six {
        animation: tiao 0.5s 1s linear infinite alternate;
      }
      .seven {
        animation: tiao 0.4s linear infinite alternate;
      }
    }
    .audiodivTime {
      line-height: 40px;
      font-size: 12px;
      color: #6b5acd;
      margin: 0 10px 0 0;
    }
  }
  .pageCont {
    padding: 0 16px 32px;
    font-size: 14px;
    color: #333333;
    line-height: 24px;
    .pageTime {
      margin: 0 0 4px 0;
    }
    ::v-deep {
      .maskIcon {
        height: 17px;
        vertical-align: -3px;
      }
    }
  }
  .homeFooter {
    width: 100%;
    //height: 72px;
    //background: #000;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
    //border-radius: 12px;
    position: fixed;
    top: 0;
    right: 0;
    box-sizing: border-box;
    //padding: 12px;
    z-index: 9;
    .homeFooterImg {
      width: 100%;
      //vertical-align: middle;
    }
    //.homeFooterImg {
    //  height: 48px;
    //  width: 48px;
    //  display: block;
    //}
    .homeFooterDiv {
      line-height: 48px;
      color: #333333;
      margin: 0 0 0 8px;
      font-size: 14px;
    }
    .homeFooterBtn {
      width: 88px;
      height: 32px;
      background: #6b5acd;
      border-radius: 16px;
      text-align: center;
      line-height: 32px;
      margin: 8px 0 0 0;
      overflow: hidden;
      color: #fff;
      font-size: 14px;
      img {
        width: 18px;
        height: 18px;
        margin: 7px 0;
        vertical-align: top;
      }
    }
  }
}
</style>
